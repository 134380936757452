<script>
//棒グラフのコンポーネントクラス
//このファイルはscriptのみ記載する
import { Bar } from 'vue-chartjs'
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Bar,
  props: ['chartData', 'options'], // propsに chartData と options を定義
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}

</script>